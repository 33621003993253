.map {
  position: absolute;
  width: 100%;
  height: 100%;
}

.mapLoadingScreen {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.ol-attribution li {
  font-size: 10px;
}

.ol-attribution a {
  font-size: 10px;
}


.ol-scale-line {
  display: none;
  position: absolute;
  top: 8px;
  left: 8px;
  background: #FFF;
  border-radius: 4px;
  height: 26px;
}

.ol-scale-line-inner {
  border: 1px solid ;
  border-top: none;
  color: #333333;
  font-size: 10px;
  text-align: center;
  margin: 4px;
}

.olPopup {
  background: #FFF;
  z-index: 1;
  border-radius: 4px;
}

.olPopup:after,
.olPopup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.olPopup:after {
  border-top-color: #FFF !important;
  border-width: 7px;
  left: 48px;
  margin-left: -7px;
}

.popupCloser.popupCloser {
  position: absolute;
  right: 0;
  top: 0;
}

.mapExpand {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  z-index: 1;
}

.ol-attribution ul {
  font-size: 10px;
}


