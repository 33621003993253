.zoomControlsWrapper {
  position: absolute;
  top: 45px;
  right: 8px;
  background: #fff;
  border-radius: 4px;
  z-index: 1;
}

.zoomControlsButton {
  background: none;
	color: inherit;
	border: none;
	padding: 4px;
	font: inherit;
	cursor: pointer;
  outline: inherit;
  display: block;
  &:focus {
    outline:none;
  }
}